import {
  DeleteFilled,
  EditFilled,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { Button, Layout, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  deleted,
  getAll,
  getAllDeleted,
  restore,
  restoreAfip,
  setDeletedPending,
  setPending,
} from '../redux/actions/comprobanteActions';
import { callStatus } from '../utils/constants';
import { convertDate, hasPermiso } from '../utils/funtions';
import RestorePopup from './RestorePopup';
import CustomCellRender from './base/CustomCellRender';
import MetaTable from './base/MetaTable';
import ComprobanteViewer from './viewer/ComprobanteViewer';
import ComprobanteTransformEditor from './ComprobanteTransformEditor';
const { confirm, error, success } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const ComprobanteList = ({
  location,
  stateData,
  comprobanteTipo,
  formaPago,
  estadoCobro,
  dispatch,
}) => {
  const [showRestore, setShowRestore] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [rowEdit, setRowEdit] = useState(null);
  const [showViewer, setShowViewer] = useState(false);
  const [showTransform, setShowTransform] = useState(false);

  const history = useHistory();
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stateData.list.status === callStatus.LIST_SUCCESS) {
      const data = [
        ...stateData.list.data.map((item) => {
          return {
            ...item,
            fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
          };
        }),
      ];

      setDataSource(data);
    }

    // eslint-disable-next-line
  }, [stateData]);

  const handleRestore = () => {
    setShowRestore(true);
    getAllDeleted(dispatch);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleConfirm = (item) => {
    if (item.tipo_id > 0 && item.estado_afip_id === 2) {
      error({
        title: 'Este comprobante no puede eliminarse, debe Anularlo',
        onOk() {},
      });
    } else {
      confirm({
        title: 'Esta a punto de eliminar un registro, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          handleDelete(item);
        },
        onCancel() {},
      });
    }
  };

  const handleEditor = (data) => {
    if (data.tipo_id > 0 && data.estado_afip_id !== 2) {
      confirm({
        title: 'Este comprobante necesita CAE, ¿desea volver a solicitarlo?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          dispatch(restoreAfip(data.id));
        },
        onCancel() {
          history.push(`/comprobantes/comprobante/${data.id}`);
        },
      });
    } else {
      history.push(`/comprobantes/comprobante/${data.id}`);
    }
  };

  const handleAdd = () => {
    history.push(`/comprobantes/comprobante/0`);
  };

  const handlePrint = (data) => {
    if (data.tipo_id > 0 && data.estado_afip_id !== 2) {
      confirm({
        title: 'Este comprobante necesita CAE, ¿desea volver a solicitarlo?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          dispatch(restoreAfip(data.id));
        },
        onCancel() {},
      });
    } else {
      setRowEdit(data);
      setShowViewer(true);
    }
  };

  const handleHideViewer = () => {
    setShowViewer(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleTransform = (data) => {
    setRowEdit(data);
    setShowTransform(true);
  };

  const handleHideTransform = () => {
    setShowTransform(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
    },
  ];

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEditor(record)} />
          {hasPermiso(322) && (
            <DeleteFilled onClick={(e) => handleConfirm(record)} />
          )}
          <PrinterOutlined onClick={(e) => handlePrint(record)} />
        </Space>
      ),
      width: 90,
      ellipsis: true,
    },

    {
      width: 150,
      ellipsis: true,
      title: 'Tipo',
      dataIndex: 'tipo',
      onFilter: (value, record) => {
        return record.tipo_id === value;
      },
      sorter: (a, b) => a.tipo.nombre.localeCompare(b.tipo.nombre),
      filterMultiple: true,
      filters: comprobanteTipo.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.letra
          ? value.nombre + ' ' + value.letra
          : value.nombre;
        return value;
      }),
      render: (text, record) => record.tipo.nombre + ' ' + record.tipo.letra,
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Fecha',
      dataIndex: 'fecha',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Número',
      dataIndex: 'numero',
      sorter: (a, b) => a.numero.localeCompare(b.numero),
      search: true,
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Cliente',
      dataIndex: 'cliente_nombre',
      sorter: (a, b) => a.cliente_nombre.localeCompare(b.cliente_nombre),
      search: true,
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Total',
      dataIndex: 'total',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      width: 180,
      ellipsis: true,
      title: 'Estado',
      dataIndex: 'estado_cobro',
      onFilter: (value, record) => {
        return record.estado_cobro_id === value;
      },
      sorter: (a, b) =>
        a.estado_cobro.nombre.localeCompare(b.estado_cobro.nombre),
      filterMultiple: true,
      filters: estadoCobro.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
      render: (text, record) => {
        let colorEstado = '';
        switch (record.estado_cobro_id) {
          case 1:
            colorEstado = 'red';
            break;
          case 2:
            colorEstado = 'orange';
            break;
          case 3:
            colorEstado = 'green';
            break;
          default:
            colorEstado = 'black';
            break;
        }

        return (
          <span style={{ color: colorEstado }}>
            {record.estado_cobro.nombre}
          </span>
        );
      },
    },
    {
      width: 200,
      ellipsis: true,
      title: 'CAE',
      dataIndex: 'cae',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Forma de Pago',
      dataIndex: 'forma_pago',
      onFilter: (value, record) => {
        return record.forma_pago_id === value;
      },
      sorter: (a, b) => a.forma_pago.nombre.localeCompare(b.forma_pago.nombre),
      filterMultiple: true,
      filters: formaPago.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
      render: (text, record) => record.forma_pago.nombre,
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => {
        return hasPermiso(321) &&
          record.iva > 0 &&
          (record.tipo_id === -5 ||
            record.tipo_id === -6 ||
            record.tipo_id === -7) ? (
          <Space size="middle">
            <Button onClick={(e) => handleTransform(record)}>Convertir</Button>
          </Space>
        ) : null;
      },
      width: 200,
    },
  ];

  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage(
        'El Comprobante se actualizó con éxito',
        'success',
        () => {}
      );
      dispatch(setPending());
    }
    if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        <MetaTable
          dataSource={dataSource}
          dataColumns={columns}
          bordered={false}
          selection={false}
          loading={
            stateData.list.status === callStatus.LIST_IN_PROCESS ||
            stateData.object.status === callStatus.LIST_IN_PROCESS
          }
          pagination={true}
        />
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleAdd()}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleRestore()}
            >
              Recuperar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>

      {showViewer && (
        <ComprobanteViewer
          id={rowEdit.id}
          onCancel={handleHideViewer}
          visible={true}
        />
      )}

      {showTransform && (
        <ComprobanteTransformEditor
          id={rowEdit.id}
          onCancel={handleHideTransform}
          visible={true}
        />
      )}

      {showRestore &&
        stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS && (
          <RestorePopup
            columns={restoreColumns}
            data={stateData.deleted.data}
            onCancel={handleRestoreCancel}
            onOk={handleRestoreOk}
          />
        )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { comprobante, comprobanteTipo, formaPago, estadoCobro } = state;
  const stateData = comprobante;
  return {
    stateData,
    comprobanteTipo,
    formaPago,
    estadoCobro,
  };
};
export default connect(mapStateToProps)(withRouter(ComprobanteList));
