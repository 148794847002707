import React, { useEffect } from 'react';
import Navbar from './Navbar';
import { connect } from 'react-redux';
import { callStatus } from './utils/constants';
import Login from './Login';
import { doGetCache } from './redux/CacheActions';
import { Spin, Layout } from 'antd';
const { Content } = Layout;

const App = ({
  dispatch,
  auth,
  condicionIva,
  moneda,
  comprobanteTipo,
  puntoVenta,
  alicuota,
  chequeTipo,
  chequeEstado,
  tarjetaTipo,
  tarjetaEmpresa,
  retencionTipo,
  cobroTipo,
  condicionVenta,
  configuracion,
  formaPago,
  estadoCobro,
  productoTipo,
}) => {
  useEffect(() => {
    if (auth.status === callStatus.SUCCESS) {
      doGetCache(dispatch);
    }
    // eslint-disable-next-line
  }, [auth]);

  if (auth.status !== callStatus.SUCCESS) {
    return (
      <>
        <Login />
      </>
    );
  }

  const getCacheStatus = () => {
    if (
      condicionIva.list.status === callStatus.LIST_SUCCESS &&
      moneda.list.status === callStatus.LIST_SUCCESS &&
      comprobanteTipo.list.status === callStatus.LIST_SUCCESS &&
      puntoVenta.list.status === callStatus.LIST_SUCCESS &&
      alicuota.list.status === callStatus.LIST_SUCCESS &&
      chequeTipo.list.status === callStatus.LIST_SUCCESS &&
      chequeEstado.list.status === callStatus.LIST_SUCCESS &&
      tarjetaTipo.list.status === callStatus.LIST_SUCCESS &&
      tarjetaEmpresa.list.status === callStatus.LIST_SUCCESS &&
      retencionTipo.list.status === callStatus.LIST_SUCCESS &&
      cobroTipo.list.status === callStatus.LIST_SUCCESS &&
      condicionVenta.list.status === callStatus.LIST_SUCCESS &&
      configuracion.list.status === callStatus.LIST_SUCCESS &&
      formaPago.list.status === callStatus.LIST_SUCCESS &&
      estadoCobro.list.status === callStatus.LIST_SUCCESS &&
      productoTipo.list.status === callStatus.LIST_SUCCESS
    ) {
      return true;
    } else {
      return false;
    }
  };

  return getCacheStatus() ? (
    <Navbar />
  ) : (
    <div style={{ height: '100vh' }}>
      <Layout style={{ height: '100%' }}>
        <Content>
          <Spin style={{ height: '100vh' }} tip="Cargando configuración">
            <Layout style={{ height: '100%' }}></Layout>
          </Spin>
        </Content>
      </Layout>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    auth,
    condicionIva,
    moneda,
    comprobanteTipo,
    puntoVenta,
    alicuota,
    chequeTipo,
    chequeEstado,
    tarjetaTipo,
    tarjetaEmpresa,
    retencionTipo,
    cobroTipo,
    condicionVenta,
    configuracion,
    formaPago,
    estadoCobro,
    productoTipo,
  } = state;

  return {
    auth,
    condicionIva,
    moneda,
    comprobanteTipo,
    puntoVenta,
    alicuota,
    chequeTipo,
    chequeEstado,
    tarjetaTipo,
    tarjetaEmpresa,
    retencionTipo,
    cobroTipo,
    condicionVenta,
    configuracion,
    formaPago,
    estadoCobro,
    productoTipo,
  };
};
export default connect(mapStateToProps)(App);
