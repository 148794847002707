import { getAll as configuracion } from './actions/configuracionActions';
import {
  getAllAlicuota,
  getAllChequeEstado,
  getAllChequeTipo,
  getAllCobroTipo,
  getAllComprobanteObservacion,
  getAllComprobanteTipo,
  getAllCondicionIva,
  getAllCondicionVenta,
  getAllEstadoCobro,
  getAllFormaPago,
  getAllMoneda,
  getAllProductoTipo,
  getAllPuntoVenta,
  getAllRetencionTipo,
  getAllTarjetaEmpresa,
  getAllTarjetaTipo,
} from './actions/staticActions';

export const doGetCache = async (dispatch) => {
  getAllCondicionIva(dispatch);
  getAllMoneda(dispatch);
  getAllComprobanteTipo(dispatch);
  getAllPuntoVenta(dispatch);
  getAllAlicuota(dispatch);
  configuracion(dispatch);
  getAllChequeTipo(dispatch);
  getAllChequeEstado(dispatch);
  getAllTarjetaTipo(dispatch);
  getAllTarjetaEmpresa(dispatch);
  getAllRetencionTipo(dispatch);
  getAllCobroTipo(dispatch);
  getAllCondicionVenta(dispatch);
  getAllComprobanteObservacion(dispatch);
  getAllFormaPago(dispatch);
  getAllEstadoCobro(dispatch);
  getAllProductoTipo(dispatch);
};
