import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  FileAddOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get, transform } from '../redux/actions/comprobanteActions';
import { callStatus } from '../utils/constants';
import moment from 'moment';
import dayjs from 'dayjs';
import styled from 'styled-components';

import {
  compareObject,
  convertDate,
  validateAsociados,
} from '../utils/funtions';
import AsociadosPopup from './AsociadosPopup';

const { confirm, error, success, info } = Modal;
const TitleCard = styled.div`
  display: flex;
  align-items: center;
`;
const ComprobanteTransformEditor = ({
  dispatch,
  id,
  stateData,
  comprobanteTipo,
  puntoVenta,
  visible,
  onCancel,
}) => {
  const [showAddAsociados, setShowAddAsociados] = useState(false);
  const [tipoId, setTipoId] = useState(null);
  const [puntoVentaId, setPuntoVentaId] = useState(null);
  const [tipoAsociado, setTipoAsociado] = useState(null);

  const [form] = Form.useForm();

  const format = 'YYYY-MM-DD';
  const formatDisplay = 'DD/MM/YYYY';

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    }
    // eslint-disable-next-line
  }, []);
  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'info':
        info({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (stateData.object.status === callStatus.OBJECT_PENDING) {
      let fecha = moment(moment(stateData.object.data.fecha));

      form.setFieldsValue({ fecha: fecha });
      setInitial({ fecha: fecha });
    } else if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = {};

      data['id'] = stateData.object.data.id;

      data['fecha'] = moment(moment(stateData.object.data.fecha));

      let tipoAsociado = null;
      let puntoVenta = null;

      if (
        stateData.object.data.tipo_id !== -5 &&
        stateData.object.data.asociados.length &&
        stateData.object.data.asociados.length > 0
      ) {
        data['asociados'] = stateData.object.data.asociados.map((item) => {
          tipoAsociado = item.tipo_id;
          puntoVenta = item.punto_venta_id;
          return {
            asociado_id: item.asociado_id,
            asociado_fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
            asociado_numero: item.numero,
            asociado_tipo: item.tipo,
            asociado_tipo_id: item.tipo_id,
            asociado_total: item.total,
            asociado_cotizacion: item.cotizacion_dolar,
          };
        });
        setTipoAsociado(tipoAsociado);
      }

      if (tipoAsociado) {
        data['tipo_id'] = getTipo(tipoAsociado, stateData.object.data.tipo_id);
        data['punto_venta_id'] = puntoVenta;
      }

      form.setFieldsValue(data);
      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage(
        'El comprobante se guardó con éxito',
        'success',
        onCancel
      );
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  const getTipo = (tipoAsociado, tipoComprobante) => {
    if (tipoComprobante === -7) {
      switch (tipoAsociado) {
        case 1:
          return 2;
        case 6:
          return 7;
        case 11:
          return 12;

        default:
          return null;
      }
    } else {
      switch (tipoAsociado) {
        case 1:
          return 3;
        case 6:
          return 8;
        case 11:
          return 13;

        default:
          return null;
      }
    }
  };

  const onCreate = (data) => {
    data.fecha = dayjs(new Date(data.fecha).toISOString()).format(format);

    if (validateAsociados(data.tipo_id, false, data.asociados)) {
      handleShowMessage(
        'Debe elegir un Comprobante Asociado',
        'error',
        () => {}
      );
    } else {
      dispatch(transform(data));
    }
  };

  const getTipos = (id) => {
    const tipoId = stateData.object.data.tipo_id;

    if (tipoId === -5) {
      return id === 1 || id === 6 || id === 11 ? true : false;
    }

    if (tipoId === -6) {
      return id === 3 || id === 8 || id === 13 ? true : false;
    }

    if (tipoId === -7) {
      return id === 2 || id === 7 || id === 12 ? true : false;
    }
  };

  const handleConfirm = (data) => {
    confirm({
      title: 'Esta a punto de convertir un Comprobante, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        onCreate(data);
      },
      onCancel() {},
    });
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  const handleAsociadosCancel = () => {
    setShowAddAsociados(false);
  };

  const handleAsociadosOk = (data) => {
    let asociados = form.getFieldValue('asociados')
      ? form.getFieldValue('asociados')
      : [];

    asociados = [
      ...asociados,
      ...data.map((item) => {
        if (!asociados.some((value) => value.asociado_id === item.id)) {
          return {
            asociado_id: item.id,
            asociado_fecha: dayjs(
              new Date(moment(moment(convertDate(item.fecha)))).toISOString()
            ).format(formatDisplay),
            asociado_numero: item.numero,
            asociado_total: item.total,
            asociado_tipo: item.tipo_nombre,
            asociado_tipo_id: item.tipo_id,
            asociado_cotizacion: item.cotizacion_dolar,
          };
        } else {
          return null;
        }
      }),
    ];

    form.setFieldsValue({
      asociados: asociados.filter((item) => item !== null),
      items: undefined,
    });

    setShowAddAsociados(false);
  };

  const handleAsociadosSearch = () => {
    if (form.getFieldValue('tipo_id') && form.getFieldValue('punto_venta_id')) {
      setShowAddAsociados(true);
    } else {
      handleShowMessage(
        'Debe seleccionar Tipo de Comprobante y Punto de Venta para asociar comprobantes',
        'info',
        () => {}
      );
    }
  };

  const onChangeTipo = (value) => {
    setTipoId(value);

    form.setFieldsValue({
      asociados: undefined,
    });
  };

  const onChangePuntoVenta = (value) => {
    setPuntoVentaId(value);
    form.setFieldsValue({
      asociados: undefined,
    });
  };

  const isComprobanteNota = () => {
    const tipo = stateData.object.data.tipo_id;

    switch (tipo) {
      case -6:
      case -7:
        return true;

      default:
        return false;
    }
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={'Convertir Comprobante'}
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleConfirm(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        width={650}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 8]} style={{ marginBottom: '10px' }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <span style={{ fontSize: '16px' }}>
                  <b>
                    {' '}
                    {stateData.object.data.tipo?.nombre}{' '}
                    {stateData.object.data.tipo?.letra}{' '}
                    {stateData.object.data.numero}
                  </b>
                </span>
              </Col>

              {tipoAsociado === -5 && (
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <span style={{ fontSize: '14px', color: 'red' }}>
                    <b>
                      Debe convertir los comprobantes asociados al tipo
                      correspondiente para poder realizar esta operación
                    </b>
                  </span>
                </Col>
              )}

              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="fecha"
                  label="Fecha emisión"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <DatePicker
                    disabled={tipoAsociado === -5}
                    format={formatDisplay}
                    style={{ width: '100%' }}
                    initialValue={moment(new Date())}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="punto_venta_id"
                  label="Punto de Venta"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select
                    disabled={tipoAsociado === -5}
                    onChange={onChangePuntoVenta}
                    open={
                      tipoAsociado && tipoAsociado !== -5 ? false : undefined
                    }
                  >
                    {puntoVenta.list.data
                      .filter((item) => item.id > 1)
                      .map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {`${item.nombre} - ${item.descripcion}`}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item
                  name="tipo_id"
                  label="Tipo"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select
                    disabled={tipoAsociado === -5}
                    onChange={onChangeTipo}
                    open={
                      tipoAsociado && tipoAsociado !== -5 ? false : undefined
                    }
                  >
                    {comprobanteTipo.list.data
                      .filter((item) => getTipos(item.id))
                      .map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.letra
                            ? item.nombre + ' ' + item.letra
                            : item.nombre}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="observacion" label="Observaciones">
                  <Input.TextArea rows={3} disabled={tipoAsociado === -5} />
                </Form.Item>
              </Col>
            </Row>
            {stateData.object.status === callStatus.OBJECT_SUCCESS &&
              isComprobanteNota() && (
                <Card
                  extra={
                    !tipoAsociado && (
                      <PlusCircleOutlined
                        style={{ fontSize: '18px' }}
                        onClick={() => handleAsociadosSearch()}
                      />
                    )
                  }
                  title={
                    <TitleCard>
                      <Avatar
                        icon={<FileAddOutlined />}
                        size="small"
                        style={{ backgroundColor: '#4f43ba' }}
                      />
                      <span style={{ marginLeft: '3px' }}>
                        Comprobantes Asociados
                      </span>
                    </TitleCard>
                  }
                  style={{ marginBottom: '10px' }}
                  size="small"
                >
                  <Row>
                    <Form.List name="asociados">
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          <Row
                            gutter={[8, 0]}
                            style={{
                              width: '100%',
                              textAlign: 'center',
                              fontWeight: '500',

                              display:
                                (!form.getFieldValue('asociados') ||
                                  form.getFieldValue('asociados').length ===
                                    0) &&
                                'none',
                            }}
                            align="top"
                            // justify="center"
                          >
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                              Tipo
                            </Col>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                              Fecha
                            </Col>

                            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                              Número
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                              Total
                            </Col>
                            <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
                          </Row>
                          {fields.map(
                            ({ key, name, fieldKey, ...restField }, index) => (
                              <Row
                                gutter={[8, 0]}
                                style={{ width: '100%' }}
                                key={index}
                                align="top"
                              >
                                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'asociado_tipo']}
                                    fieldKey={[fieldKey, 'asociado_tipo']}
                                    style={{ marginBottom: '5px' }}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'asociado_fecha']}
                                    fieldKey={[fieldKey, 'asociado_fecha']}
                                    style={{ marginBottom: '5px' }}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'asociado_numero']}
                                    fieldKey={[fieldKey, 'asociado_numero']}
                                    style={{ marginBottom: '5px' }}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'asociado_total']}
                                    fieldKey={[fieldKey, 'asociado_total']}
                                    style={{ marginBottom: '5px' }}
                                  >
                                    <Input readOnly />
                                  </Form.Item>
                                </Col>

                                {!tipoAsociado && (
                                  <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                    {(!stateData.object.data.id ||
                                      (stateData.object.data.tipo_id < 0 &&
                                        !stateData.object.data
                                          .parent_asociado)) && (
                                      <MinusCircleOutlined
                                        style={{ color: 'red' }}
                                        onClick={() => {
                                          remove(name);
                                        }}
                                      />
                                    )}
                                  </Col>
                                )}
                              </Row>
                            )
                          )}
                        </>
                      )}
                    </Form.List>
                  </Row>
                </Card>
              )}
          </Form>
        </Spin>

        {showAddAsociados ? (
          <AsociadosPopup
            cliente={stateData.object.data.cliente_id}
            tipo={tipoId}
            puntoVenta={puntoVentaId}
            onCancel={handleAsociadosCancel}
            onOk={handleAsociadosOk}
            itemSelected={form.getFieldValue('asociados')}
          />
        ) : (
          false
        )}
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { comprobante, comprobanteTipo, puntoVenta } = state;
  const stateData = comprobante;
  return {
    stateData,
    comprobanteTipo,
    puntoVenta,
  };
};
export default connect(mapStateToProps)(ComprobanteTransformEditor);
