import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { convertDate2 } from '../../utils/funtions';

// Create styles
const styles = StyleSheet.create({
  contacto: {
    marginBottom: 2,
    fontSize: 10,
  },
});

const Encabezado = ({ data, qr = null, type, model = 'venta' }) => {
  return (
    <View
      fixed
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 5,
        borderBottom: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Image
          style={{
            width: '200px',
          }}
          src="logo.png"
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '53%',
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: 1,
            padding: '0px 5px',
          }}
        >
          <Text style={{ fontSize: 30, textAlign: 'center' }}>
            {type === 'comprobante' ? data.tipo.letra : 'R'}
          </Text>
          {qr && (
            <Text style={styles.contacto}>
              COD. {data.tipo.id.toString().padStart(3, 0)}
            </Text>
          )}
        </View>
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          {type === 'comprobante' ? (
            <>
              <Text style={[styles.contacto, { fontSize: 18 }]}>
                {data.tipo_id === -5 ? 'Comprobante' : data.tipo.nombre}
              </Text>
              {model === 'venta' ? (
                <Text style={styles.contacto}>
                  Pto Venta: {data.punto_venta.nombre.padStart(5, '0')} Comp Nº:{' '}
                  {data.numero.padStart(8, '0')}
                </Text>
              ) : (
                <Text style={styles.contacto}>Comp Nº: {data.numero}</Text>
              )}
            </>
          ) : (
            <>
              {model === 'venta' ? (
                <Text style={[styles.contacto, { fontSize: 18 }]}>
                  {data.tipo_id === 1
                    ? 'Recibo de Cobro'
                    : 'Recibo de Anticipo'}
                </Text>
              ) : (
                <Text style={[styles.contacto, { fontSize: 18 }]}>
                  {data.tipo_id === 1 ? 'Recibo de Pago' : 'Recibo de Anticipo'}
                </Text>
              )}
              <Text style={styles.contacto}>
                Nº: {data.numero.padStart(8, '0')}
              </Text>
            </>
          )}
          <Text style={styles.contacto}>
            Fecha Emisión: {convertDate2(data.fecha)}
          </Text>
          {data.user && data.user.nombre && (
            <Text style={styles.contacto}>Vendedor: {data.user.nombre}</Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default Encabezado;
