import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { callStatus } from '../utils/constants';
import { Modal, Form, Input, Row, Col, Select, Spin } from 'antd';
import { get, save } from '../redux/actions/productoListaPrecioActions';
import { getAll as productos } from '../redux/actions/productoActions';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { compareObject } from '../utils/funtions';
import { cloneDeep } from 'lodash';
import { getAll as listas } from '../redux/actions/listaActions';

const { confirm, error, success } = Modal;
const { Option } = Select;

const ProductoListaPrecioEditor = ({
  dispatch,
  id,
  parentId,
  stateData,
  visible,
  onCancel,
  producto,
  alicuota,
  lista,
}) => {
  const [form] = Form.useForm();
  const [resultSearch, setResultSearch] = useState([]);
  const [productoList, setProductoList] = useState(null);
  const [listaList, setListaList] = useState([]);

  useEffect(() => {
    if (id) {
      dispatch(get(id));
    } else {
      productos(dispatch);
    }

    // if (!parentId) {
    //   listas(dispatch);
    // }

    listas(dispatch);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setListaList(lista.list.data);
    // eslint-disable-next-line
  }, [lista]);
  const handleShowMessage = (message, type, handler) => {
    switch (type) {
      case 'success':
        success({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      case 'error':
        error({
          title: message,
          onOk() {
            handler();
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let data = cloneDeep(stateData.object.data);

    data['lista_id'] = parentId;
    form.setFieldsValue(data);
    setInitial(data);

    if (stateData.object.status === callStatus.OBJECT_SUCCESS) {
      let data = cloneDeep(stateData.object.data);
      if (stateData.object.data.producto) {
        data[
          'producto_nombre'
        ] = `${data.producto.codigo} - ${data.producto.nombre}`;

        // setResultSearch([stateData.object.data.producto]);

        form.setFieldsValue(data);
      }

      setInitial(data);
    } else if (stateData.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      handleShowMessage(
        'El Producto/ListaPrecio se guardó con éxito',
        'success',
        onCancel
      );
    } else if (stateData.object.status === callStatus.OBJECT_FAILED) {
      handleShowMessage(stateData.object.error, 'error', () => {});
    }

    // eslint-disable-next-line
  }, [stateData.object]);

  useEffect(() => {
    setProductoList(producto.list.data);
    // eslint-disable-next-line
  }, [producto]);

  const onCreate = (data) => {
    // data.lista_id = parentId;

    dispatch(save(data));
  };

  const onSearch = (value) => {
    const result = productoList.filter(
      (item) =>
        (item.nombre &&
          item.nombre.toLowerCase().includes(value.toLowerCase())) ||
        (item.codigo && item.codigo.toLowerCase().includes(value.toLowerCase()))
    );

    setResultSearch(result);
  };

  const onChange = (value) => {
    const res = productoList.find((item) => item.id === value);
    if (res) {
      form.setFieldsValue({ precio_compra: res.precio_compra });
      calcPrecio();
    }
  };

  const calcPrecio = (precio = false) => {
    if (precio === true) {
      const precio_compra = form.getFieldValue('precio_compra');
      const precio = form.getFieldValue('precio');

      if (precio_compra && precio) {
        const ganancia =
          ((parseFloat(precio) - parseFloat(precio_compra)) /
            parseFloat(precio_compra)) *
          100;

        form.setFieldsValue({ ganancia: ganancia.toFixed(2) });
      }
    } else {
      const precio_compra = form.getFieldValue('precio_compra');
      const ganancia = form.getFieldValue('ganancia');

      if (precio_compra && ganancia) {
        const precio =
          parseFloat(precio_compra) +
          (parseFloat(precio_compra) * parseFloat(ganancia)) / 100;
        form.setFieldsValue({ precio: precio.toFixed(2) });
      }
    }
  };

  const [initial, setInitial] = useState({});

  const handleOnCancel = () => {
    if (compareObject(initial, form.getFieldsValue())) {
      confirm({
        title: 'Posee cambios sin guardar, desea continuar?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Si',
        cancelText: 'No',
        autoFocusButton: 'cancel',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    } else {
      onCancel();
    }
  };

  return (
    <>
      <Modal
        centered
        maskClosable={false}
        visible={visible}
        title={
          stateData.object.data.id
            ? 'Editar ProductoListaPrecio'
            : 'Nuevo ProductoListaPrecio'
        }
        okText="Guardar"
        cancelText="Salir"
        onCancel={handleOnCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Spin
          spinning={stateData.object.status === callStatus.OBJECT_IN_PROCESS}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={stateData.object.data}
          >
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="id" label="id" style={{ display: 'none' }}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="lista_id" label="Lista">
                  <Select open={false}>
                    {listaList.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {!id ? (
                  <Form.Item
                    name="producto_id"
                    label="Producto"
                    rules={[
                      {
                        required: true,
                        message: 'Requerido',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      optionFilterProp="children"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={onSearch}
                      onChange={onChange}
                      notFoundContent={null}
                      allowClear
                    >
                      {resultSearch.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.codigo
                            ? `${item.codigo} - ${item.nombre}`
                            : item.nombre}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item
                      name="producto_id"
                      label="producto_id"
                      style={{ display: 'none' }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item name="producto_nombre" label="Producto">
                      <Input readOnly />
                    </Form.Item>
                  </>
                )}
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="precio_compra"
                  label="Precio Compra"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input type="number" onBlur={calcPrecio} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="ganancia"
                  label="Ganancia"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input type="number" onBlur={calcPrecio} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="precio"
                  label="Precio"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Input type="number" onBlur={() => calcPrecio(true)} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  name="alicuota_id"
                  label="Alicuota Iva"
                  rules={[
                    {
                      required: true,
                      message: 'Requerido',
                    },
                  ]}
                >
                  <Select>
                    {alicuota.list.data.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.nombre}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  const { productoListaPrecio, producto, alicuota, lista } = state;
  const stateData = productoListaPrecio;
  return {
    stateData,
    producto,
    alicuota,
    lista,
  };
};
export default connect(mapStateToProps)(ProductoListaPrecioEditor);
