import {
  alicuotaApi,
  chequeApi,
  cobroApi,
  comprobanteApi,
  comprobanteObservacionApi,
  comprobanteTipoApi,
  condicionIvaApi,
  condicionVentaApi,
  formaPagoApi,
  monedaApi,
  productoApi,
  puntoVentaApi,
  retencionApi,
  tarjetaApi,
} from '../../utils/api';
import { getListDataAction } from '../../utils/DataAction';

export const getAllCondicionIva = getListDataAction({
  dataName: 'CONDICION_IVA',
  api: condicionIvaApi,
  relUrl: '',
});

export const getAllMoneda = getListDataAction({
  dataName: 'MONEDA',
  api: monedaApi,
  relUrl: '',
});

export const getAllComprobanteTipo = getListDataAction({
  dataName: 'COMPROBANTE_TIPO',
  api: comprobanteTipoApi,
  relUrl: '',
});

export const getAllPuntoVenta = getListDataAction({
  dataName: 'PUNTO_VENTA',
  api: puntoVentaApi,
  relUrl: '',
});

export const getAllAlicuota = getListDataAction({
  dataName: 'ALICUOTA',
  api: alicuotaApi,
  relUrl: '',
});

export const getAllChequeTipo = getListDataAction({
  dataName: 'CHEQUE_TIPO',
  api: chequeApi,
  relUrl: 'tipos',
});

export const getAllChequeEstado = getListDataAction({
  dataName: 'CHEQUE_ESTADO',
  api: chequeApi,
  relUrl: 'estados',
});

export const getAllTarjetaTipo = getListDataAction({
  dataName: 'TARJETA_TIPO',
  api: tarjetaApi,
  relUrl: 'tipos',
});

export const getAllTarjetaEmpresa = getListDataAction({
  dataName: 'TARJETA_EMPRESA',
  api: tarjetaApi,
  relUrl: 'empresas',
});

export const getAllRetencionTipo = getListDataAction({
  dataName: 'RETENCION_TIPO',
  api: retencionApi,
  relUrl: 'tipos',
});

export const getAllCobroTipo = getListDataAction({
  dataName: 'COBRO_TIPO',
  api: cobroApi,
  relUrl: 'tipos',
});

export const getAllCondicionVenta = getListDataAction({
  dataName: 'CONDICION_VENTA',
  api: condicionVentaApi,
  relUrl: '',
});

export const getAllComprobanteObservacion = getListDataAction({
  dataName: 'COMPROBANTE_OBSERVACION',
  api: comprobanteObservacionApi,
  relUrl: '',
});

export const getAllFormaPago = getListDataAction({
  dataName: 'FORMA_PAGO',
  api: formaPagoApi,
  relUrl: '',
});

export const getAllEstadoCobro = getListDataAction({
  dataName: 'ESTADO_COBRO',
  api: comprobanteApi,
  relUrl: 'estados-cobro',
});

export const getAllProductoTipo = getListDataAction({
  dataName: 'PRODUCTO_TIPO',
  api: productoApi,
  relUrl: 'tipos',
});
