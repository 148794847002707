import React, { useState, useEffect } from 'react';
import ProductoDepositoEditor from './ProductoDepositoEditor';
import { connect } from 'react-redux';
import {
  getAllByParent,
  setPending,
  deleted,
} from '../redux/actions/productoDepositoActions';
import { Button, Space, Menu, Dropdown, Row, Col, Layout, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  // SearchOutlined,
  EditFilled,
  DeleteFilled,
  // DownloadOutlined,
  PlusCircleOutlined,
  UnorderedListOutlined,
  FormatPainterFilled,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import BaseBox from './base/BaseBox';
import MetaTable from './base/MetaTable';
import { callStatus } from '../utils/constants';
import { hasPermiso } from '../utils/funtions';

const { confirm } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

const MenuButton = styled(Button)`
  width: 100%;
  text-align: left;
`;

const ProductoDepositoList = ({
  location,
  stateData,
  dispatch,
  match: {
    params: { id },
  },
  configuracion,
}) => {
  const [view, setView] = useState(1);

  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  useEffect(() => {
    dispatch(getAllByParent(id));
    // eslint-disable-next-line
  }, []);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          {hasPermiso(522) && (
            <DeleteFilled onClick={(e) => handleConfirm(record)} />
          )}
        </Space>
      ),
      width: 60,
      ellipsis: true,
    },

    {
      width: 300,
      ellipsis: true,
      title: 'Producto',
      dataIndex: 'producto_nombre',
      sorter: (a, b) => a.producto_nombre.localeCompare(b.producto_nombre),

      search: true,
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Código',
      dataIndex: 'producto_codigo',
      sorter: (a, b) => a.producto_codigo.localeCompare(b.producto_codigo),
      search: true,
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Cantidad',
      dataIndex: 'cantidad',
    },
    {
      width: 200,
      ellipsis: true,
      title: 'Cantidad mínima',
      dataIndex: 'cantidad_minima',
    },
    {
      width: 200,
      ellipsis: true,
      title: `${
        configuracion.list.data.length > 0
          ? configuracion.list.data.find(
              (item) => item.nombre === 'label_posicion_x'
            ).valor
          : 'Posición X'
      }`,
      dataIndex: 'posicion_x',
      sorter: (a, b) => a.posicion_x.localeCompare(b.posicion_x),
    },
    {
      width: 200,
      ellipsis: true,
      title: `${
        configuracion.list.data.length > 0
          ? configuracion.list.data.find(
              (item) => item.nombre === 'label_posicion_y'
            ).valor
          : 'Posición Y'
      }`,
      dataIndex: 'posicion_y',
      sorter: (a, b) => a.posicion_x.localeCompare(b.posicion_y),
    },
    {
      width: 200,
      ellipsis: true,
      title: `${
        configuracion.list.data.length > 0
          ? configuracion.list.data.find(
              (item) => item.nombre === 'label_posicion_z'
            ).valor
          : 'Posición Z'
      }`,
      dataIndex: 'posicion_z',
      sorter: (a, b) => a.posicion_x.localeCompare(b.posicion_z),
    },
  ];

  const grid = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };

  const menuView = (
    <Menu>
      <Menu.Item key="1">
        <MenuButton onClick={() => setView(1)} icon={<UnorderedListOutlined />}>
          Tabla
        </MenuButton>
      </Menu.Item>
      <Menu.Item key="2">
        <MenuButton onClick={() => setView(2)} icon={<UnorderedListOutlined />}>
          Box
        </MenuButton>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        {view === 1 ? (
          // <BaseTable
          //   columns={columns}
          //   dataSource={stateData.list.data}
          //   handleDelete={handleDelete}
          //   handleEdit={handleEdit}
          // />
          <MetaTable
            dataSource={stateData.list.data}
            dataColumns={columns}
            bordered={false}
            selection={false}
            loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
          />
        ) : (
          <Row gutter={8}>
            {stateData.list.data.map((item) => {
              return (
                <Col {...grid} key={item.id} style={{ marginBottom: '10px' }}>
                  <BaseBox
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    item={item}
                    title={item.nombre}
                    subtitle={item.cuit}
                    moreInfo={item.telefono}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <ProductoDepositoEditor
          id={rowEdit.id}
          parentId={id}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}
      {/* 
      {showRestore &&
      stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
        <RestorePopup
          columns={restoreColumns}
          data={stateData.deleted.data}
          onCancel={handleRestoreCancel}
          onOk={handleRestoreOk}
        />
      ) : (
        false
      )} */}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { productoDeposito, configuracion } = state;
  const stateData = productoDeposito;
  return {
    stateData,
    configuracion,
  };
};
export default connect(mapStateToProps)(withRouter(ProductoDepositoList));
