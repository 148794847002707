import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/Dashboard';
import ProveedorCtaCteList from '../components/ProveedorCtaCteList';
import ProveedorList from '../components/ProveedorList';

import ChequeList from '../components/ChequeList';
import ClienteContactoList from '../components/ClienteContactoList';
import ClienteCtaCteList from '../components/ClienteCtaCteList';
import ClienteList from '../components/ClienteList';
import CobroEditor from '../components/CobroEditor';
import CobroList from '../components/CobroList';
import ComprobanteCompraEditor from '../components/ComprobanteCompraEditor';
import ComprobanteCompraList from '../components/ComprobanteCompraList';
import ComprobanteEditor from '../components/ComprobanteEditor';
import ComprobanteList from '../components/ComprobanteList';
import DepositoList from '../components/DepositoList';
import Estadisticas from '../components/Estadisticas';
import ListaList from '../components/ListaList';
import ProductoDepositoList from '../components/ProductoDepositoList';
import ProductoList from '../components/ProductoList';
import ProductoListaPrecioList from '../components/ProductoListaPrecioList';
import RubroList from '../components/RubroList';
import ServicioList from '../components/ServicioList';

import { Provider } from 'react-redux';
import Breadcrumbs from '../components/Breadcrumbs';
import PagoEditor from '../components/PagoEditor';
import PagoList from '../components/PagoList';
import store from '../redux/store';
import CuotaList from '../components/CuotaList';
import RubroCompraList from '../components/RubroCompraList';
import BancoList from '../components/BancoList';
import UbicacionList from '../components/UbicacionList';

const r = [
  { path: '/', name: 'Home', Component: Dashboard },
  { path: '/dashboard', name: 'Home', Component: Dashboard },
  { path: '/estadisticas', name: 'Estadisticas', Component: Estadisticas },

  { path: '/proveedores', name: 'Proveedores', Component: ProveedorList },
  {
    path: '/proveedores/ctacte/:id',
    name: 'CtaCte',
    Component: ProveedorCtaCteList,
  },
  { path: '/clientes', name: 'Clientes', Component: ClienteList },
  {
    path: '/clientes/ctacte/:id',
    name: 'CtaCte',
    Component: ClienteCtaCteList,
  },

  { path: '/servicios', name: 'Servicios', Component: ServicioList },
  { path: '/productos', name: 'Productos', Component: ProductoList },
  { path: '/listas', name: 'Listas', Component: ListaList },
  { path: '/depositos', name: 'Depositos', Component: DepositoList },
  { path: '/rubros', name: 'Rubros', Component: RubroList },
  {
    path: '/rubros-compra',
    name: 'Rubros de Compra',
    Component: RubroCompraList,
  },

  { path: '/ubicaciones', name: 'Ubicaciones', Component: UbicacionList },
  { path: '/bancos', name: 'Bancos', Component: BancoList },

  {
    path: '/listas/lista-productos/:id',
    name: 'Productos',
    Component: ProductoListaPrecioList,
  },

  {
    path: '/depositos/deposito-productos/:id',
    name: 'Productos',
    Component: ProductoDepositoList,
  },

  {
    path: '/clientes/cliente-contactos/:id',
    name: 'Contactos',
    Component: ClienteContactoList,
  },
  {
    path: '/comprobantes',
    name: 'Comprobantes',
    Component: ComprobanteList,
  },
  {
    path: '/comprobantes/comprobante/:id',
    name: 'Comprobante',
    Component: ComprobanteEditor,
  },
  {
    path: '/comprobantes/cuotas/:id',
    name: 'Cuotas',
    Component: CuotaList,
  },
  {
    path: '/cobros',
    name: 'Cobros',
    Component: CobroList,
  },
  {
    path: '/cobros/cobro/:id',
    name: 'Cobro',
    Component: CobroEditor,
  },
  {
    path: '/cheques',
    name: 'Cheques',
    Component: ChequeList,
  },
  {
    path: '/gastos',
    name: 'Gastos',
    Component: ChequeList,
  },
  {
    path: '/pagos',
    name: 'Pagos',
    Component: PagoList,
  },
  {
    path: '/pagos/pago/:id',
    name: 'Pago',
    Component: PagoEditor,
  },
  {
    path: '/compras',
    name: 'Compras',
    Component: ComprobanteCompraList,
  },
  {
    path: '/compras/compra/:id',
    name: 'Compra',
    Component: ComprobanteCompraEditor,
  },
];

const routes = (
  <Provider store={store}>
    <Switch>
      {r.map(({ path, name, Component }, key) => (
        <Route
          exact
          path={path}
          key={key}
          render={(props) => {
            const crumbs = r
              .filter(({ path }) => props.match.path.includes(path))
              .map(({ path, ...rest }) => ({
                path: Object.keys(props.match.params).length
                  ? Object.keys(props.match.params).reduce(
                      (path, param) =>
                        path.replace(`:${param}`, props.match.params[param]),
                      path
                    )
                  : path,
                ...rest,
              }));

            return (
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Breadcrumbs crumbs={crumbs} />
                {/*<div style={{ flex: '1' }}> */}
                <Component {...props} />
                {/* </div> */}
              </div>
            );
          }}
        />
      ))}
    </Switch>
  </Provider>
);

export default routes;
