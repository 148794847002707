import React, { useState, useEffect } from 'react';
import ProductoEditor from './ProductoEditor';
import { connect } from 'react-redux';
import {
  getAll,
  setPending,
  deleted,
  setDeletedPending,
  getAllDeleted,
  restore,
} from '../redux/actions/productoActions';
import {
  addProductos,
  setPending as setPendingProductoListaPrecio,
} from '../redux/actions/productoListaPrecioActions';

import { callStatus } from '../utils/constants';
import { Button, Space, Row, Col, Layout, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  EditFilled,
  DeleteFilled,
  PlusCircleOutlined,
  SelectOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import RestorePopup from './RestorePopup';
import BaseBox from './base/BaseBox';
import ProductoListaPrecioSelector from './ProductoListaPrecioSelector';
import MetaTable from './base/MetaTable';
import CustomCellRender from './base/CustomCellRender';
import { hasPermiso } from '../utils/funtions';
const { confirm, error, success } = Modal;

const { Content, Header, Footer } = Layout;

const SelectedContainer = styled.div`
  flex: 1;
`;

const ActionsContainer = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 10px;
`;

// const MenuButton = styled(Button)`
//   width: 100%;
//   text-align: left;
// `;

const ProductoList = ({
  location,
  stateData,
  moneda,
  dispatch,
  lista,
  productoListaPrecio,
  productoTipo,
}) => {
  const [view] = useState(1);
  const [showRestore, setShowRestore] = useState(false);
  const [showAddLista, setShowAddLista] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [rowEdit, setRowEdit] = useState(null);

  useEffect(() => {
    getAll(dispatch);
    // eslint-disable-next-line
  }, []);

  const handleEdit = (data) => {
    setRowEdit(data);
    setShowEdit(true);
  };

  const handleRestore = () => {
    setShowRestore(true);
    getAllDeleted(dispatch);
  };

  const handleRestoreCancel = () => {
    setShowRestore(false);
    dispatch(setDeletedPending());
  };

  const handleRestoreOk = (data) => {
    dispatch(restore({ data: data }));
    setShowRestore(false);
  };

  const handleDelete = (data) => {
    dispatch(deleted(data.id));
  };

  const handleHideEditor = () => {
    setShowEdit(false);
    setRowEdit(null);
    dispatch(setPending());
  };

  const handleShowAddLista = () => {
    if (selectedRowKeys.length > 0) {
      setShowAddLista(true);
      const firstProducto = stateData.list.data.find(
        (item) => item.id === selectedRowKeys[0]
      );

      const selectedRowObjects = stateData.list.data.filter((item) =>
        selectedRowKeys.includes(item.id)
      );

      const distintos = selectedRowObjects.filter(
        (item) => item.alicuota_id !== firstProducto.alicuota_id
      );
      if (distintos.length === 0) {
        setAlicuotaDefault(firstProducto.alicuota_id);
      } else {
        setAlicuotaDefault(-1);
      }
    } else {
      error({
        title: 'Debe seleccionar al menos un producto de la lista',
        onOk() {},
      });
    }
  };

  const handleHideAddLista = () => {
    setShowAddLista(false);
    setAlicuotaDefault(null);
  };

  const handleConfirm = (item) => {
    confirm({
      title: 'Esta a punto de eliminar un registro, desea continuar?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Si',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      onOk() {
        handleDelete(item);
      },
      onCancel() {},
    });
  };

  const restoreColumns = [
    {
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
    },
  ];

  const columns = [
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditFilled onClick={(e) => handleEdit(record)} />
          {hasPermiso(532) && (
            <DeleteFilled onClick={(e) => handleConfirm(record)} />
          )}
        </Space>
      ),
      width: 60,
      // ellipsis: true,
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Nombre',
      dataIndex: 'nombre',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      search: true,
    },

    {
      width: 150,
      ellipsis: true,
      title: 'Código',
      dataIndex: 'codigo',
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      search: true,
    },
    {
      width: 150,
      ellipsis: true,
      title: 'Precio Compra',
      dataIndex: 'precio_compra',
      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'$'} />
      ),
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Alicuota IVA',
      dataIndex: 'alicuota_nombre',

      render: (text) => (
        <CustomCellRender text={text} justify={'space-between'} prefix={'%'} />
      ),
    },
    {
      width: 300,
      ellipsis: true,
      title: 'Proveedor',
      dataIndex: 'proveedor_nombre',
      sorter: (a, b) => a.proveedor_nombre.localeCompare(b.proveedor_nombre),
      search: true,
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Moneda',
      dataIndex: 'moneda_nombre',
      onFilter: (value, record) => {
        return record.moneda_id === value;
      },
      sorter: (a, b) => a.moneda_nombre.localeCompare(b.moneda_nombre),
      filterMultiple: true,
      filters: moneda.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
    },
    {
      width: 100,
      ellipsis: true,
      title: 'Tipo',
      dataIndex: 'tipo',
      onFilter: (value, record) => {
        return record.tipo_id === value;
      },
      sorter: (a, b) => a.tipo.nombre.localeCompare(b.tipo.nombre),
      filterMultiple: true,
      filters: productoTipo.list.data.map((value) => {
        value['value'] = value.id;
        value['text'] = value.nombre;
        return value;
      }),
      render: (text, record) => (record.tipo ? record.tipo.nombre : ''),
    },
  ];

  const grid = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };

  // const menuView = (
  //   <Menu>
  //     <Menu.Item key="1">
  //       <MenuButton onClick={() => setView(1)} icon={<UnorderedListOutlined />}>
  //         Tabla
  //       </MenuButton>
  //     </Menu.Item>
  //     <Menu.Item key="2">
  //       <MenuButton onClick={() => setView(2)} icon={<UnorderedListOutlined />}>
  //         Box
  //       </MenuButton>
  //     </Menu.Item>
  //   </Menu>
  // );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [alicuotaDefault, setAlicuotaDefault] = useState(null);

  const handleAddLista = (data) => {
    dispatch(addProductos({ data: data, productos: selectedRowKeys }));
    setShowAddLista(false);
    setAlicuotaDefault(null);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (productoListaPrecio.object.status === callStatus.OBJECT_SAVE_SUCCESS) {
      success({
        title: 'Los productos se guardaron con éxito',
        onOk() {
          dispatch(setPendingProductoListaPrecio());
        },
      });
    } else if (productoListaPrecio.object.status === callStatus.OBJECT_FAILED) {
      error({
        title: productoListaPrecio.object.error,
        onOk() {},
      });
    }

    // eslint-disable-next-line
  }, [productoListaPrecio.object]);

  return (
    <Layout style={{ height: '100%' }}>
      <Header></Header>
      <Content>
        {view === 1 ? (
          <MetaTable
            dataSource={stateData.list.data}
            dataColumns={columns}
            bordered={false}
            selection={true}
            handleSelectedRowKeys={setSelectedRowKeys}
            loading={stateData.list.status === callStatus.LIST_IN_PROCESS}
            pagination={true}
          />
        ) : (
          <Row gutter={8}>
            {stateData.list.data.map((item) => {
              return (
                <Col {...grid} key={item.id} style={{ marginBottom: '10px' }}>
                  <BaseBox
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    item={item}
                    title={item.nombre}
                    subtitle={item.cuit}
                    moreInfo={item.telefono}
                  />
                </Col>
              );
            })}
          </Row>
        )}
      </Content>
      <Footer>
        <FooterContainer>
          <SelectedContainer></SelectedContainer>
          <ActionsContainer>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              size="small"
              onClick={(e) => handleEdit({})}
            >
              Agregar
            </Button>
            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleRestore()}
            >
              Recuperar
            </Button>

            <Button
              type="primary"
              icon={<SelectOutlined />}
              size="small"
              style={{ marginLeft: '3px' }}
              onClick={(e) => handleShowAddLista()}
            >
              Agregar a Lista
            </Button>
          </ActionsContainer>
        </FooterContainer>
      </Footer>
      {showEdit ? (
        <ProductoEditor
          id={rowEdit.id}
          onCancel={handleHideEditor}
          // onCreate={handleSaveEditor}
          visible={true}
        />
      ) : (
        false
      )}

      {showAddLista && alicuotaDefault ? (
        <ProductoListaPrecioSelector
          onCancel={handleHideAddLista}
          onOk={handleAddLista}
          visible={true}
          alicuotaDefault={alicuotaDefault}
        />
      ) : (
        false
      )}

      {showRestore &&
      stateData.deleted.status === callStatus.LIST_DELETED_SUCCESS ? (
        <RestorePopup
          columns={restoreColumns}
          data={stateData.deleted.data}
          onCancel={handleRestoreCancel}
          onOk={handleRestoreOk}
        />
      ) : (
        false
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => {
  const { producto, moneda, lista, productoListaPrecio, productoTipo } = state;
  const stateData = producto;
  return {
    stateData,
    moneda,
    lista,
    productoListaPrecio,
    productoTipo,
  };
};
export default connect(mapStateToProps)(withRouter(ProductoList));
